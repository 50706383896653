import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { PathMissingRedirectGuard } from './shared/guards/path-missing-redirect.guard';
import { WelcomeComponent } from './welcome/welcome.component';

export const RouterDefinitions: Routes = [
  {
    path: AppRoutes.WELCOME,
    component: WelcomeComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: `${AppRoutes.SYMPHONY_ENSEMBLES}/:app`,
    // loadChildren: () => import('@xpo-ltl/symphony-ensemble-ui').then((m) => m.SymphonyEnsemblesModule),
    loadChildren: () => import('./symphony-ensembles/pnd-ensembles.module').then((m) => m.PndEnsemblesModule),
    canLoad: [XpoAuthenticationGuard, PathMissingRedirectGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.WELCOME,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false })],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}
