import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class PathMissingRedirectGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const code = next.params.app;
    if (!code) {
      this.router.navigate([AppRoutes.WELCOME]);
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (segments.find((seg) => seg.path === ':app')) {
      this.router.navigate([AppRoutes.WELCOME]);
      return false;
    }
    return true;
  }
}
